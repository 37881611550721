<template>
  <div class="pagePadding">
    <p class="table_title">修改密码</p>
    <div class="clearfix">
      <div class="item"><span>当前密码：</span><i-input v-model="postData.oldPwd" placeholder="请输入" class="iviewIptWidth250" type="password"></i-input></div>
      <div class="item"><span>修改密码：</span><i-input v-model="postData.changePwd" placeholder="请输入" class="iviewIptWidth250" type="password"></i-input></div>
      <div class="item"><span>确认密码：</span><i-input v-model="postData.surePwd" placeholder="请输入" class="iviewIptWidth250" type="password"></i-input></div>
    </div>
    <div class="do">
      <span class="pageBtn finger btnSure" @click="sure"> 确定 </span>
      <span class="pageBtn finger btnReset marginLeft20" @click="upPage"> 重置 </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      postData: {
        oldPwd: '',
        changePwd: '',
        surePwd: ''
      }
    }
  },
  created() {},
  methods: {
    sure() {
      if (!this.postData.changePwd || !this.postData.oldPwd || !this.postData.surePwd) {
        this.$Message.warning('请填写完整后提交')
        return null
      }
      if (this.postData.changePwd === this.postData.oldPwd) {
        this.$Message.warning('新密码不能与原密码相同')
        return null
      }
      // let test = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$/
      // if (!test.test(this.postData.changePwd)) {
      //   this.$Message.warning('您的密码必须为8-16位字符，其中包含数字、大写字母和小写字母')
      //   return null
      // }
      if (this.postData.changePwd !== this.postData.surePwd) {
        this.$Message.warning('修改密码和确认密码不一致！')
        return null
      }
      this.$http.get(this.$api.systemRsa).then(res => {
        let oldPasswd = this.$getRsaCode(res.data, this.postData.oldPwd)
        let newPassword = this.$getRsaCode(res.data, this.postData.surePwd)
        let data = {
          old_password: oldPasswd,
          new_password: newPassword
        }
        this.$http.post(this.$api.permissionResetPassword, data, true).then(res => {
          this.$Message.success('修改密码成功')
          this.postData = {}
        })
      })
    },
    upPage() {
      this.$http
        .put(this.$api.dealerUserManagePasswd, {}, true)
        .then(res => {
          this.$Message.success('密码重置为123456')
        })
        .catch(res => {
          this.$Message.warning('重置密码失败！')
        })
    }
  }
}
</script>
<style scoped lang="less">
.table_title {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #525b6d;
  line-height: 25px;
  margin-bottom: 18px;
}
.do {
  margin-top: 32px;
}
.item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  span {
    color: #000000;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
